/**
 * Clase IU = Interfaz de Usuario
 */
import mitt from 'mitt'
import IUMsg from './IUMsg.js'
import IUDlg from './IUDlg.js'
import IUHerramienta from './IUHerramienta.js'
import IUSpinner from './IUSpinner.js'

function IU() {
  // Carga de componentes
  this.bus = mitt()
  this.msg = new IUMsg()
  this.dlg = new IUDlg()
  this.hrr = new IUHerramienta()
  this.spinner = new IUSpinner()
}

export default IU

/*
 * IUMsg = Mensaje de Interfaz de usuario
 */
import Swal from 'sweetalert2'

function IUMsg() {
  const swal = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  this.success = function(mensaje, ajustes) {
    ajustes = ajustes || {}
    swal.fire(Object.assign({
      icon: "success",
      title: mensaje
    },ajustes))
  }

  this.error = function(mensaje, ajustes) {
    ajustes = ajustes || {}
    swal.fire(Object.assign({
      icon: "error",
      title: mensaje
    },ajustes))
  }

  this.warning = function(mensaje, ajustes) {
    ajustes = ajustes || {}
    swal.fire(Object.assign({
      icon: "warning",
      title: mensaje
    },ajustes))
  }

  this.info = function(mensaje, ajustes) {
    ajustes = ajustes || {}
    swal.fire(Object.assign({
      icon: "info",
      title: mensaje
    },ajustes))
  }

  this.question = function(mensaje, ajustes) {
    ajustes = ajustes || {}
    swal.fire(Object.assign({
      icon: "question",
      title: mensaje
    },ajustes))
  }
}

export default IUMsg

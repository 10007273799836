// Modo desarrollo
// var API = 'http://api.argus.black.local/api'
// var APIINV = 'http://api.argus.black.local/api/inventario'
// var APIHOTS = 'http://api.argus.black.local/api/hotspot'
// var APITRAFIC = 'http://trafico.argus.black.local/api'


// Modo para trabajar con servidorpruebas.cloud
// var API = 'https://api.argus.black.servidorpruebas.cloud/api'
// var APIINV = 'https://api.argus.black.servidorpruebas.cloud/api/inventario'
// var APIHOTS = 'https://api.argus.black.servidorpruebas.cloud/api/hotspot'
// var APITRAFIC = 'https://trafico.argus.black.servidorpruebas.cloud/api'


// Modo producción con SSL
var API = 'https://api.argus.black/api'
var APIINV = 'https://api.argus.black/api/inventario'
var APIHOTS = 'https://api.argus.black/api/hotspot'
var APITRAFIC = 'https://trafico.argus.black/api'


export default API
export { API, APIINV, APIHOTS, APITRAFIC }

<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import SesionSrv from '@/services/SesionSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import UsuarioSrv from '@/services/UsuarioSrv.js'
import VistasCampos from '@/services/VistasCampos.js'
import CuentaSrv from '@/services/CuentaSrv.js'
import moment from 'moment'
import { todoGetters, todoComputed, todoMethods } from "@/state/helpers"
import { layoutMethods } from "@/state/helpers"
import axios from 'axios'
export default {
  name: "App",
  // uc = bandera usuario cargado, utf = usuario tiene foto, credval = credencial válida, susargus = Estado de la suscripcion argusblack
  computed: {
    ...todoComputed,
    ...todoGetters
  },
  data () {
    return {
      intervals: {
        actualizacion: null
      },
      rutas: [
        "modulos", "sistemaCuenta", "sistemaPlanesIsp", "actualizarPlanIsp",
        "sistemaModuloHotspot", "actualizarPlanHotspot", "sistemaModuloInventario",
        "actualizarPlanInventario", "sistemaModuloVpn", "actualizarPlanVpn",
        "sistemaSoporte", "aprobado", "pendiente", "fallido"
      ]
    }
  },
  watch: {
    // uc = bandera de usuario cargado
    // Esta bandera llega a cambiar cuando el usuario ha sido cargado.
    // La carga del usuario se realiza después de haber validado la credencial
    uc(newVal, oldVal) {
      if(newVal == false) return // Evita continuar

      if(document.getElementById("recaptcha")) document.head.removeChild(document.getElementById("recaptcha"))

      let badgeCaptcha = document.getElementsByClassName("grecaptcha-badge")
      for (let i = 0; i < badgeCaptcha.length; i++) {
        const e = badgeCaptcha[i];
        if(e){
          e.innerHTML = "";
          e.style = "display: none";
        }
      }

      // Si estamos en la ruta "login" y el usuario ya fue cargado, se manda al selectorModulo 
      if(this.$route.name == 'login')
        this.$router.push({name: 'selectorModulo'})
    },
    credval(newVal, oldVal) {
      let self = this

      if(newVal == true) {
        // Si la credencial es válida se manda a cargar al usuario
        self.cargarUsuarioActual()
        self.cargarAliasJson()
      }
      else {
        // Si la credencial no es válida y estamos en una ruta diferente al login, 
        // se manda al login
        if ( self.$route.path != '/' ) {
          iu.msg.warning('La sesión se encuentra cerrada')
          self.$router.push({ name: 'login' })
        }
      }
    },
    susargus(newSus, oldSus){
      var self = this

      if(self.uc == false) return

      if(newSus == false) {
        self.mostrarMensajeEmpresaDesactivada()
        // self.cerrarSesion()
      }
    },
    empresaact(newAct, oldAct){
      var self = this

      if(self.uc == false) return

      if(newAct == false) {
        self.mostrarMensajeEmpresaDesactivada()
        self.cerrarSesion()
      }
    }
  },
  created() {
    let self = this

    self.verificarSesionAbierta()
    // Carga el estado de ACL front
    SistemaSrv.estadoAcl().then(response => {
      self.actualizarEstadoAcl({ estado: response.data })
    })

    // Carga del año actual
    SistemaSrv.anio().then(response => {
      self.actualizarAnio({ anio: response.data })
    })

    // Carga de la fecha actual
    SistemaSrv.fecha().then(response => {
      self.actualizarFecha({ fecha: response.data })
    })

    // Carga de la fecha de notificacion
    SistemaSrv.fechaNotificacion().then(response => {
      let fecha_notificacion = moment(response.data, 'YYYY-MM-DD HH:mm:ss').locale('es-mx').format('DD/MM/YYYY')
      let fecha_actual = moment().locale('es-mx').format('DD/MM/YYYY')

      if(fecha_notificacion == fecha_actual) self.cargarSaldos(fecha_notificacion)
    })

    self.activarGuardianRutas()
    self.cargarAliasJson()

    // Cargas iniciales
    SistemaSrv.version().then(response => {
      self.cargarVersion({ version: response.data.version })
    })

    SistemaSrv.estadoSuscripcion().then(response => {
      let estado = response.data == "0" ? false : true
      self.actualizarEstadoSuscripcionArgusblack({ estado: estado })

      if(self.uc == false) {
        if(self.susargus == false) {
          self.mostrarMensajeEmpresaDesactivada()
        }
      }
    }).catch(error => {
      self.actualizarEstadoSuscripcionArgusblack({ estado: false })
    })

    SistemaSrv.estadoEmpresa().then(response => {
      let estado = response.data == "0" ? false : true
      self.actualizarEstadoEmpresa({ estado: estado })

      if(self.uc == false) {
        if(self.empresaact == false) {
          self.mostrarMensajeEmpresaDesactivada()
          self.cerrarSesion()
        }
      }
    }).catch(error => {
      self.actualizarEstadoEmpresa({ estado: false })
    })

    self.intervals.actualizacion = setInterval(function() {
      if(self.uc == true) {
        // Carga el estado de la suscripcion de argusblack
        SistemaSrv.estadoSuscripcion().then(response => {
          let estado = response.data == "0" ? false : true
          self.actualizarEstadoSuscripcionArgusblack({ estado: estado })
        }).catch(error => {
          self.actualizarEstadoSuscripcionArgusblack({ estado: false })
        })
      }

      if(self.uc == true) {
        // Carga el estado la empresa activo o inactivo
        SistemaSrv.estadoEmpresa().then(response => {
          let estado = response.data == "0" ? false : true
          self.actualizarEstadoEmpresa({ estado: estado })
        }).catch(error => {
          self.actualizarEstadoEmpresa({ estado: false })
        }) 
      }
    }, 3600000)
    // }, 6000)
  },
  beforeUnmount: function() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    ...todoMethods,
    ...layoutMethods,
    activarGuardianRutas() {
      let self = this

      // Guardían general de cada ruta
      self.$router.beforeEach((to, from, next) => {
        // Si el ACL está inactivo, entonces se evita validar los permisos a las vistas
        if(self.acl == false || to.name == "recuperarCuenta" || to.name == 'recuperarIdentidades' || to.name == 'soporteParaArgusBlack') {
          next()
          return
        }

        let tienePermiso = self.tienePermiso(to.name, 'vista'),
          token = localStorage.getItem('argusblack.token'),
          uc = self.uc,           // Usuario cargado
          utf = self.utf          // Usuario tiene foto

        // Si no está presente el token
        if(token == null) {
          switch(to.name) {
            case 'login': next(); break;              // y va al login, deja que continue
            default: next({ name: 'login' }); break;  // y va a otra ruta, redirige al login
          }
        }

        // Si se está intentando ir al login, pero el usuario ya se logueo con una
        // credenciál válida, se reenvia al selectorModulo, manda un mensaje y evita continuar
        if( this.credval && to.name == 'login') {
          // iu.msg.warning('No puedes regresar al Login, ya estas logueado')
          return
        }

        // Verifica que el usuario tenga permiso para acceder a la vista solicitada
        // solo cuando el usuario ha sido cargado
        // La siguiente línea es un Hack para evitar mandar al selectorModulo,
        // a aquel usuario que acaba de loguearse y aun no es cargado los datos de su cuenta
        // en el lado front-end
        if ( uc && !(to.name == 'selectorModulo' || to.name == 'dashboard' || to.name == 'administradorFunciones') && !tienePermiso ) {
          iu.msg.warning(`No tienes permiso para acceder a <strong style="color: #e65853">${to.name}</strong>, revisa los permisos de rol con tu administrador de sistema`, { width: '650px' })
          next({ name: 'selectorModulo' })
          return
        }

        if( uc && !self.susargus && !self.rutas.includes(to.name) ) {
          iu.msg.warning('No podrás acceder a la vista <strong style="color:red">'+to.name+'</strong>, hasta que hayas pagado tu mensualidad correspondiente.', {width: '650px'})
          next({ name: 'modulos' })
          return
        }

        // Cualquier otro caso, lo envía a la ruta de destino
        next()
      })
    },
    cargarAliasJson:function(){
      var self = this

      VistasCampos.vistasCamposJSON().then(response => {
        self.cargarAlias({ alias: response.data })
      })
    },
    cargarUsuarioActual: function() {
      var self = this

      UsuarioSrv.actual().then(response => {
        // Si se obtuvo el usuario, se manda a guardar en el storage
        self.cargarUsuario({ usuario: response.data })
        self.cargarAjustesUsuario()
        self.cargarMonedaSistema()
      }).catch(error => {
        // Si ocurrió un error evita continuar
        iu.msg.warning('Ocurrió un error al momento de cargar el usuario, intentalo más tarde')
        self.limpiarUsuario()
        localStorage.removeItem('argusblack.token')

        // Manda a refrescar la app
        setTimeout(() => {
          location.reload()
        }, 2000)
      })
    },
    cargarAjustesUsuario() {
      UsuarioSrv.ajustesJSON().then(response => {
        let ajustes = response.data

        localStorage.setItem("argusblack.theme.mode", ajustes.mode)
        if (ajustes.mode === "dark") {
          document.body.setAttribute("data-layout-mode", "dark")
          this.changeLayoutMode({ layoutMode: 'dark' })
          this.changeTopbar({ topbar: "dark" })
          this.changeSidebarColor({ sidebarColor: "dark" })
        } else {
          document.body.setAttribute("data-layout-mode", "light")
          this.changeLayoutMode({ layoutMode: 'light' })
          this.changeTopbar({ topbar: "light" })
          this.changeSidebarColor({ sidebarColor: "dark" })
        }
        localStorage.setItem("argusblack.theme.layoutScrollable", ajustes.layoutScrollable)
        this.changeLayoutPosition({ layoutScrollable: ajustes.layoutScrollable })
        localStorage.setItem("argusblack.theme.color", ajustes.color)
        this.changeSidebarColor({ sidebarColor: ajustes.color })
        localStorage.setItem("argusblack.theme.topbar", ajustes.topbar)
        this.changeTopbar({ topbar: ajustes.topbar })
        localStorage.setItem("argusblack.theme.sidebarType", ajustes.sidebarType)
        this.changeLeftSidebarType({ leftSidebarType: ajustes.sidebarType })
      })
    },
    cargarMonedaSistema() {
      var self = this
      SistemaSrv.monedaJSON().then(response => {
        self.actualizarMonedaSistema({ moneda: response.data })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la moneda del sistema'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarSaldos(fecha){
      let prom1 = CuentaSrv.saldoCuentaMaster()
      let prom2 = CuentaSrv.totalNuevaSuscripcion()

      axios.all([prom1, prom2]).then(response => {
        let saldo_master = response[0].data
        let total_suscripcion = response[1].data

        if(saldo_master < total_suscripcion){
          let saldo_restante = total_suscripcion - saldo_master
          iu.msg.warning('Suscripción está por caducar, realiza tu pago antes del día '+fecha+', saldo insuficiente, faltan '+saldo_restante+' USD para renovar su suscripción')
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'no se cargo el saldo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cerrarSesion: function() {
      var self = this

      SesionSrv.cerrar()
      self.limpiarUsuario()
      self.$router.push({ name: 'login' })

      // Eliminación de items de argusblack del localStorage
      for (var key in localStorage) {
        if(key.substring(0, 10) == 'argusblack')
          localStorage.removeItem(key)
      }

      // Hace un refresh de la APP
      setTimeout(function(){
        location.reload()
      }, 300)
    },
    mostrarMensajeEmpresaDesactivada() {
      iu.msg.warning(
        `Su empresa ha sido desactivada por falta de pago, ingrese a <a href="https://livaur.com">https://livaur.com</a> para realizar el pago de su suscripción.`,
        {width: '850px'}
      )
    },
    verificarSesionAbierta() {
      let self = this
      // Verifica que la sesión se encuentre abierta, de lo contrario, manda al login
      SesionSrv.estaAbierta().then(response => {
        self.credencialValida()
      }).catch(error => {
        self.credencialInvalida()
        self.limpiarUsuario()
      })
    }
  }
}
</script>
import axios from 'axios'
import API from '@/API.js'
import md5 from 'md5'

const apiClient = axios.create({
  baseURL: `${API}/usuarios`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actual() {
    return apiClient.get('actual.json')
  },

  actualizar(usuario) {
    let formData = new FormData(),
      headers = { headers: { 'Content-Type': 'multipart/form-data' } }
    Object.keys(usuario).forEach(key => {
      if(usuario[key] == null) return
      formData.append(key, usuario[key])
    })

    // Asignación de los roles al formData
    if(usuario.roles != undefined) {
      formData.delete('roles')
      usuario.roles.forEach(idRol => {
        formData.append("roles[]", idRol)
      })
    }

    return apiClient.post(`${usuario.id}`, formData, headers)
  },

  actualizarAjustes(ajustes) {
    return apiClient.post(`${API}/usuarios/actual/ajustes`, ajustes)
  },

  ajustesJSON() {
    return apiClient.get(`${API}/usuarios/actual/ajustes.json`)
  },
  
  guardar(usuario) {
    let formData = new FormData(),
      headers = { headers: { 'Content-Type': 'multipart/form-data' } }
    Object.keys(usuario).forEach(key => {
      if(usuario[key] == null) return
      formData.append(key, usuario[key])
    })

    // Asignación de los roles al formData
    if(usuario.roles != undefined) {
      formData.delete('roles')
      usuario.roles.forEach(idRol => {
        formData.append("roles[]", idRol)
      })
    }
    

    return apiClient.post('', formData, headers)
  },

  actualizarFoto(idUsuario, foto) {
    let formData = new FormData(),
      headers = { headers: { 'Content-Type': 'multipart/form-data' } }
    formData.append('foto', foto, headers)

    return apiClient.post(`${idUsuario}/foto`, formData, headers)
  },

  /**
   * Usando la zona desmilitarizada, manda a realizar el cambio de clave
   * @param {string} identidad
   * @param {string} nvaClave
   */
  dmzCambiarClave(identidad, nvaClave, tokenRecaptcha) {
    return apiClient.post(`${API}/dmz/usuarios/${identidad}/clave/cambiar`, {
      clave: md5(nvaClave),
      token_recaptcha: tokenRecaptcha
    })
  },

  dmzExisteIdentidad(identidad) {
    return apiClient.get(`${API}/dmz/usuarios/identidades/${identidad}/existe`)
  },

  dmzExisteCorreo(correo) {
    return apiClient.get(`${API}/dmz/usuarios/correos/${correo}/existe`)
  },

  dmzSolicitarIdentidades(correo, params) {
    params = params || {}
    return apiClient.get(`${API}/dmz/usuarios/correos/${correo}/solicitar-identidades`, { params: params })
  },

  eliminar(idUsuario) {
    return apiClient.delete(`${idUsuario}`)
  },

  eliminarFoto(idUsuario) {
    return apiClient.delete(`${idUsuario}/foto`)
  },

  idFotoUsuarioActual() {
    return apiClient.get('actual/foto/id')
  },

  usuarioJSON(id) {
    return apiClient.get(`${id}.json`)
  },

  usuariosJSON(params) {
    return apiClient.get(`${API}/usuarios.json`, { params: params })
  }
}

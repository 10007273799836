import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/sistema`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  estadoAcl() {
    return apiClient.get('estado-acl-front')
  },

  estadoSuscripcion() {
    return apiClient.get('suscripcion/estado')
  },

  estadoEmpresa() {
    return apiClient.get('empresa/estado')
  },

  actualizar(ajustes) {
    return apiClient.post('ajustes', ajustes)
  },

  actualizarIdMoneda(idMoneda){
    return apiClient.post(`${idMoneda}/id_moneda`)
  },

  actualizarLogo(datos) {
    let formData = new FormData(),
        headers = { headers: { 'Content-Type': 'multipart/form-data' }}

    Object.keys(datos).forEach(key => {
      if(datos[key] == null) return
      formData.append(key, datos[key])
    })

    return apiClient.post("tickets-pagos/logo", formData, headers)
  },

  actualizarLogoMSI(datos) {
    let formData = new FormData(),
        headers = { headers: { 'Content-Type': 'multipart/form-data' }}

    Object.keys(datos).forEach(key => {
      if(datos[key] == null) return
      formData.append(key, datos[key])
    })

    return apiClient.post("mi-servicio-internet/logo", formData, headers)
  },

  eliminarLogo() {
    return apiClient.delete("tickets-pagos/logo")
  },

  eliminarLogoMSI() {
    return apiClient.delete("mi-servicio-internet/logo")
  },

  ajustes(nombresAjustes) {
    nombresAjustes = nombresAjustes || []
    return apiClient.get('ajustes.json', {params: {nombres_ajustes: nombresAjustes}})
  },

  anio() {
    return apiClient.get(`${API}/anio`)
  },

  apiKeyGoogleMaps() {
    return apiClient.get('google-maps/api-key')
  },

  fecha(params) {
    params = params || {}
    return apiClient.get(`${API}/fecha`, { params: params })
  },

  fechaNotificacion(){
    return apiClient.get('suscripcion/fecha-notificacion')
  },

  fechaYHora() {
    return apiClient.get('fecha-y-hora')
  },

  idCertificadoLicencia() {
    return apiClient.get('certificado-licencia/id')
  },

  /**
   * Entrega el ID de moneda por defecto
   * @returns int
   */
  idMoneda() {
    return apiClient.get('id_moneda')
  },

  mesesIncluidosEnInstalacion() {
    return apiClient.get('ajustes/meses_incluidos_en_instalacion/valor')
  },

  modo() {
    return apiClient.get(`${API}/modo-desarrollo`)
  },

  /**
   * Entrega la moneda indicada por código o ID
   */
  monedaJSON(codigo, id) {
    let params = {}
    if(codigo != undefined && codigo != null) params['codigo'] = codigo
    if(id != undefined && id != null) params['id'] = id
    return apiClient.get('moneda.json', {params: params})
  },

  monedasJSON(){
    return apiClient.get('monedas.json')
  },

  nombreEmpresa() {
    return apiClient.get('ajustes/nombre-empresa')
  },

  posicionInicial() {
    return apiClient.get('posicion-inicial.json')
  },

  precioInstalacion() {
    return apiClient.get('ajustes/precio_instalacion/valor')
  },

  paisesJSON(params) {
    params = params || {}
    return apiClient.get('paises.json', {params: params})
  },

  paypal(){
    return apiClient.get('paypal.json')
  },
  
  mercadoPago(){
    return apiClient.get('mercado-pago.json')
  },

  mercadoPagoPreference(datos){
    return apiClient.post('mercado-pago/preference', datos)
  },
  
  timezonesJSON() {
    return apiClient.get('timezones.json')
  },

  /**
   * Valor del ajuste solicitado
   * @param {string} nombre 
   * @returns 
   */
  valorAjuste(nombre) {
    return apiClient.get(`ajustes/${nombre}/valor`)
  },

  /**
   * Valor por defecto del ajuste solicitado
   * @param {string} nombre 
   * @returns 
   */
  valorPorDefectoAjuste(nombre) {
    return apiClient.get(`ajustes/${nombre}/valor-por-defecto`)
  },

  /**
   * Entrega la versión del sistema
   * @returns 
   */
  version() {
    return apiClient.get(`${API}/version`)
  }
}

import { mapState, mapGetters, mapActions } from 'vuex'

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    layoutScrollable: (state) => state.layoutScrollable,
    layoutMode: (state) => state.layoutMode,
    leftSidebarType: (state) => state.leftSidebarType,
    sidebarColor: (state) => state.sidebarColor,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar
  })
}

export const layoutMethods = mapActions('layout', [
  'changeLayoutType', 'changeLayoutMode', 
  'changeLayoutWidth', 'changeLeftSidebarType', 
  'changeTopbar', 'changeSidebarColor', 'changeLayoutPosition'
])

export const todoComputed = {
  ...mapState('todo', {
    modulo: (state) => state.modulo,
    modulos: (state) => state.modulos,
    moneda_sistema: (state) => state.moneda_sistema,
    acl: (state) => state.acl,
    uc: (state) => state.uc,
    utf: (state) => state.utf,
    credval: (state) => state.credval,
    susargus: (state) => state.susargus,
    empresaact: (state) => state.empresaact,
    usuario: (state) => state.usuario,
    alias: (state) => state.alias,
    version: (state) => state.version,
    nombre_empresa: (state) => state.nombre_empresa,
    anio: (state) => state.anio,
    fecha: (state) => state.fecha,
    fmoneda: (state) => state.fmoneda,
    fmoneda2: (state) => state.fmoneda2
  })
}

export const todoGetters ={
  ...mapGetters('todo' , [
    'uc', 'existeModulo',
    'fecha', 'tieneAlias',
    'tieneOpciones', 'tienePermiso',
    'cargarAliasCampo', 'cargarOpciones',
    'moduloSeleccionado', 'usuarioActualEsAdministrador',
    'monedaSistema'
  ])
} 

export const todoMethods = mapActions('todo', [
  'actualizarMonedaSistema', 'actualizarModuloSeleccionado',
  'actualizarEstadoAcl', 'actualizarEstadoSuscripcionArgusblack',
  'actualizarEstadoEmpresa', 'actualizarAnio', 'actualizarFecha',
  'actualizarRandom', 'actualizarTieneFotoUsuarioSesion', 
  'cargarAlias', 'cargarIdFoto', 'cargarUsuario', 'cargarVersion',
  'cargarNombreEmpresa', 'credencialInvalida',
  'credencialValida', 'limpiarUsuario', 'noMostrarInstalador'
])
export const state = {
  // Módulo seleccionado
  modulo: null,     // Si es null, indica que no está ningún módulo seleccionado 
  // Módulos disponibles en sistema
  modulos: [ 
    { nombre: 'isp', nombreRutaInicio: 'inicio', descripcion: 'Administración ISP', icono: 'radio-outline' },
    { nombre: 'hotspot', nombreRutaInicio: 'tableroHotspot', descripcion: 'Administración Hotspot', icono: 'wifi-outline' },
    { nombre: 'inventario', nombreRutaInicio: 'tableroInventario', descripcion: 'Administración Inventario', icono: 'layers-outline' },
    { nombre: 'vpn', nombreRutaInicio: 'tableroVpn', descripcion: 'Administración VPN', icono: 'shield' }
  ],
  acl: 1,           // Bandera que indica si el ACL se debe encontrar activo en el lado front
  uc: false,        // Bandera de Usuario Cargado
  utf: false,       // Usuario Tiene Foto
  credval: null,    // Credencial válida,
  susargus: true,   // Estado de la suscripcion argusblack
  empresaact: true, // estado de la empresa Activa o inactiva
  usuario: {
    id: null,
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
    permisos: [],
    root: false,
    id_foto: null,        // Probablemente se elimine
    tiene_foto: false,
    roles: []
  },
  moneda_sistema: {
    id_moneda: null,
    nombre: '',
    simbolo: '',
    codigo: '',
    activo: true
  },
  rnd: 1,
  alias:[],
  version: 0,
  nombre_empresa: '',
  anio: 1960,
  fecha: null,
  // Formato de moneda para 
  fmoneda: {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true
  },
  fmoneda2: {
    decimal: '.',
    thousands: ',',
    prefix: '$ ',
    precision: 2,
    masked: false
  },
  apiKeyGoogleMaps: 'AIzaSyB_cdh7wbLKwSHB56-fLQO2jp0xo2JRKcU',
  google_clave_recaptcha: '6Lc9ILYpAAAAAFrIjI3gcJ0uBN_f7SmxuBSV0t_v'
};

export const getters = {
  uc: state => state.uc,
  existeModulo: state => (nombreModulo) => {
    let n = state.modulos.filter( m => { return m.nombre == nombreModulo} ).length
    return n > 0
  },
  fecha: state => state.fecha,
  tieneAlias: state => (campo) => {
    if(state.alias.length == 0) return true

    if(campo == null){
      console.error('Es necesario el nombre del campo')
      return false
    }

    let alias = state.alias.find(ali => {
      return ali.campo == campo
    })

    if(alias == null){
      return true
    }

    if(alias.visible == true){
      return true
    } else {
      return false
    }
  },
  tieneOpciones: state => (campo) => {
    if(state.alias.length == 0) return true

    if(campo == null){
      console.error('Es necesario el nombre del campo')
      return false
    }

    let alias = state.alias.find(ali => {
      return ali.campo == campo
    })

    if(alias == null){
      return true
    }

    if(alias.opciones == null){
      return true
    } else {
      return false
    }
  },
  tienePermiso: state => (objetivo, tipo) => {
    if (state.usuario.permisos.length == 0) return false

    if (tipo == null) {
      console.error('Es necesario indicar en la funcion "tienePermiso" el tipo de "'+objetivo+'"')
      return false
    }

    let permiso = state.usuario.permisos.find(per => {
      return per.nombre == objetivo && per.tipo == tipo
    })
    
    // El valor de permiso es NULL 
    // significa que no tiene permiso
    if (permiso == null) {
      // console.warn(`En el "store" no existe permiso para la función ${idFuncion} `)
      return false
    }

    return true
  },
  cargarAliasCampo: state => (campo) => {
    if(state.alias.length == 0) return campo

    if(campo == null || campo == ''){
      console.error('Es necesario el nombre del campo')
      return 'Sin campo'
    }

    let alias = state.alias.find(ali => {
      return ali.campo == campo
    })

    if(alias == null){
      return campo
    }

    if(alias.alias == null || alias.alias == '') return campo
    else return alias.alias
  },
  cargarOpciones: state => (campo) => {
    if(state.alias.length == 0) return campo

    if(campo == null || campo == ''){
      console.error('Es necesario el nombre del campo')
      return 'Sin campo'
    }

    let alias = state.alias.find(ali => {
      return ali.campo == campo
    })

    if(alias == null){
      return campo
    }

    if(alias.opciones == null || alias.opciones == '') return [{nombre:campo}]
    else return JSON.parse(alias.opciones)
  },
  monedaSistema: state => () => {
    return state.moneda_sistema
  },
  moduloSeleccionado: state => () => {
    return state.modulo
  },
  usuarioActualEsAdministrador: state => () => {
    let rolAdministrador = state.usuario.roles.find(rol => rol.id == 1)
    return rolAdministrador != undefined
  }
};

export const mutations = {
  ACTUALIZAR_MONEDA_SISTEMA(state, moneda) {
    state.moneda_sistema = moneda
  },
  ACTUALIZAR_MODULO_SELECCIONADO(state, modulo) {
    state.modulo = modulo
  },
  ACTUALIZAR_ESTADO_ACL(state, estado) {
    state.acl = estado
  },
  ACTUALIZAR_ESTADO_SUSCRIPCION_ARGUSBLACK(state, estado) {
    state.susargus = estado
  },
  ACTUALIZAR_ESTADO_EMPRESA(state, estado) {
    state.empresaact = estado
  },
  ACTUALIZAR_ANIO(state, anio) {
    state.anio = anio
  },
  ACTUALIZAR_FECHA(state, fecha) {
    state.fecha = fecha
  },
  ACTUALIZAR_RANDOM(state, fecha) {
    state.rnd = fecha
  },
  ACTUALIZAR_TIENE_FOTO_USUARIO_SESION(state, tieneFoto) {
    state.usuario.tiene_foto = tieneFoto
  },
  CARGAR_ALIAS(state, alias){
    state.alias = alias
  },
  CARGAR_ID_FOTO(state, idFoto) {
    state.usuario.id_foto = idFoto
  },
  CARGAR_USUARIO(state, usuario) {
    Object.assign(state.usuario, usuario)
    state.uc = true
    state.utf = usuario.tiene_foto
  },
  CARGAR_VERSION(state, version) {
    state.version = version
  },
  CARGAR_NOMBRE_EMPRESA(state, nombreEmpresa) {
    state.nombre_empresa = nombreEmpresa
  },
  CREDENCIAL_INVALIDA(state) {
    state.credval = false
  },
  CREDENCIAL_VALIDA(state) {
    state.credval = true
  },
  LIMPIAR_USUARIO(state) {
    Object.assign(state.usuario, {
      id: null,
      nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
      permisos: []
    })
    state.uc = false
    state.utf = false
    state.credval = false

    sessionStorage.removeItem("nombreEmpresa")
    sessionStorage.removeItem("anioFecha")
    sessionStorage.removeItem("modoDesarrollo")
    
    localStorage.removeItem('argusblack.mostrar_instalador')
    localStorage.removeItem('argusblack.token')
  }
};

export const actions = {
  actualizarMonedaSistema({ commit }, { moneda }) {
    commit("ACTUALIZAR_MONEDA_SISTEMA", moneda)
  },
  actualizarModuloSeleccionado({ commit }, { modulo }) {
    commit('ACTUALIZAR_MODULO_SELECCIONADO', modulo)
  },
  actualizarEstadoAcl({ commit }, { estado }) {
    commit('ACTUALIZAR_ESTADO_ACL', estado)
  },
  actualizarEstadoSuscripcionArgusblack({ commit }, { estado }) {
    commit('ACTUALIZAR_ESTADO_SUSCRIPCION_ARGUSBLACK', estado)
  },
  actualizarEstadoEmpresa({ commit }, { estado }) {
    commit('ACTUALIZAR_ESTADO_EMPRESA', estado)
  },
  actualizarAnio({ commit }, { anio }) {
    commit('ACTUALIZAR_ANIO', anio)
  },
  actualizarFecha({ commit }, { fecha }) {
    commit('ACTUALIZAR_FECHA', fecha)
  },
  actualizarRandom({ commit }, { rnd }) {
    commit('ACTUALIZAR_RANDOM', rnd)
  },
  actualizarTieneFotoUsuarioSesion({ commit }, { tieneFoto }) {
    commit('ACTUALIZAR_TIENE_FOTO_USUARIO_SESION', tieneFoto)
  },
  cargarAlias({ commit }, { alias }){
    commit('CARGAR_ALIAS', alias)
  },
  cargarIdFoto({ commit }, { idFoto }) {
    commit('CARGAR_ID_FOTO', idFoto)
  },
  cargarUsuario({ commit }, { usuario }) {
    commit('CARGAR_USUARIO', usuario)
  },
  cargarVersion({ commit }, { version }) {
    commit('CARGAR_VERSION', version)
  },
  cargarNombreEmpresa({ commit }, { nombreEmpresa }) {
    commit('CARGAR_NOMBRE_EMPRESA', nombreEmpresa)
  },
  credencialInvalida({ commit }) {
    commit('CREDENCIAL_INVALIDA')
  },
  credencialValida({ commit }) {
    commit('CREDENCIAL_VALIDA')
  },
  limpiarUsuario({ commit }) {
    commit('LIMPIAR_USUARIO')
  }
}

import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/cuenta`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizarTipoSuscripcion(empresa) {
    return apiClient.post('actualizar-tipo-de-suscripcion', empresa)
  },

  actualizarModuloPlanContratado(modulo, datos) {
    return apiClient.patch(`${modulo}/plan-contratado`, datos)
  },

  activarModulo(modulo) {
    return apiClient.post(`${modulo}/activar`)
  },

  cuentaJSON() {
    return apiClient.get(`${API}/cuenta.json`)
  },

  cuentaMasterJSON() {
    return apiClient.get('cuenta-master.json')
  },

  estadoModulo(modulo) {
    return apiClient.get(`${modulo}/estado`)
  },

  desactivarModulo(modulo) {
    return apiClient.post(`${modulo}/desactivar`)
  },

  moduloPlanJSON(modulo, idPlan) {
    return apiClient.get(`${modulo}/planes/${idPlan}.json`)
  },

  moduloPlanesDisponiblesJSON(modulo) {
    return apiClient.get(`${modulo}/planes/disponibles.json`)
  },

  moduloPlanContratadoJSON(modulo) {
    return apiClient.get(`${modulo}/plan-contratado.json`)
  },

  saldoCuentaMaster(){
    return apiClient.get("saldo-cuenta-master")
  },

  suscripcionJSON(){
    return apiClient.get('suscripcion.json')
  },

  totalNuevaSuscripcion(){
    return apiClient.get("total-nueva-suscripcion")
  },
}
import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  // Función que actualiza el token
  actualizarToken(token) {
    // Actualización del token en el localstorage
    localStorage.setItem('argusblack.token', token)

    // Lanzamiento del evento para actualización de los tokens de los servicios
    var event = new CustomEvent('actualizar:token', { detail: token })
    document.dispatchEvent(event)
  },

  // Función para el cierre de la sesión
  cerrar() {
    // Eliminación del usuario en la sesión
    sessionStorage.removeItem('argusblack.usuario')

    // Eliminación del token
    localStorage.removeItem('argusblack.token')

    // Lanzamiento del evento
    var event = new CustomEvent('eliminar:token')
    document.dispatchEvent(event)
  },

  estaAbierta() {
    return apiClient.get('token/validar')
  }
}

/*
 * IUDlg = Dialogo de Interfaz de usuario
 *
 * LIBRERÍAS NECESARIAS:
 *  - JQuery
 */

function IUDlg() {
  var $ = window.$
  this.fn = {
    // si: function(){...},
    // no: function(){...},
    // cerrar: function() {...}
  }

  function _cargarDialogoSiNo(cnfg) {
    var iudlg = $('#iu-dialogoSiNo')
    iudlg.find('.modal-title').html(cnfg.titulo)
    iudlg.find('.modal-body').html(cnfg.mensaje)
  }

  function _crearDialogoSiNo(cnfg) {
    let template = `
        <!-- Modal -->
        <div id="iu-dialogoSiNo" class="modal inmodal"  tabindex="-1" 
            role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content animated bounceInDown">
                    <div class="modal-header">
                        <button type="button" class="btnCerrar close" data-dismiss="modal" aria-label="Close"
                          onclick="iu.dlg.fn.cerrar()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 class="modal-title" id="myModalLabel"></h4>
                    </div>
                    <div class="modal-body"></div>
                    <div class="modal-footer">
                        <button type="button" class="btnNo btn btn-default" data-dismiss="modal"
                          onclick="iu.dlg.fn.no()">
                            No
                        </button>
                        <button type="button" class="btnSi btn btn-primary" data-dismiss="modal"
                          onclick="iu.dlg.fn.si()">
                            <i class="fa fa-check"></i>
                            Si
                        </button>
                    </div>
                </div>
            </div>
        </div>
      `

    $('body').append(template)
  }

  this.siNo = function(cnfg) {
    var dialogoSiNo = $('#iu-dialogoSiNo')

    if (dialogoSiNo.parents(':last').is('html')) {
      _cargarDialogoSiNo(cnfg)
    } else {
      _crearDialogoSiNo(cnfg)
      _cargarDialogoSiNo(cnfg)
    }

    if (typeof cnfg.fnSi == 'function') this.fn.si = cnfg.fnSi
    else this.fn.si = function() {}

    if (typeof cnfg.fnNo == 'function') this.fn.no = cnfg.fnNo
    else this.fn.no = function() {}

    if (typeof cnfg.fnCerrar == 'function') this.fn.cerrar = cnfg.fnCerrar
    else this.fn.cerrar = function() {}

    $('#iu-dialogoSiNo').modal('show')
  }



  function _cargarDialogoRpd(cnfg) {
    var iurpd = $('#iu-dialogoRapido')

    iurpd.find('.modal-title').html(cnfg.titulo)
    iurpd.find('.modal-body .mensaje').html(cnfg.mensaje)
    iurpd.modal('show')
    $('#iu-dialogoRapido .respuestaUsuario').val('')
  }


  function _crearDialogoRapido(cnfg) {
    let template = `
      <!-- Modal -->
      <div id="iu-dialogoRapido" class="modal inmodal" tabindex="-1" 
          role="dialog" aria-labelledby="myModalLabel">
          <div class="modal-dialog" role="document">
              <div class="modal-content animated bounceInDown">
                  <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                      <h4 class="modal-title" id="myModalLabel"></h4>
                  </div>
                  <div class="modal-body">
                      <div class="mensaje"></div>
                      <textarea rows="5" class="form-control respuestaUsuario"></textarea>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal"
                              onclick="iu.dlg.fn.cancelar()">
                          Cancelar
                      </button>
                      <button type="button" class="btn btn-primary" data-dismiss="modal"
                              onclick="iu.dlg.fn.aceptar()">
                          <i class="fa fa-check"></i>
                          Aceptar
                      </button>
                  </div>
              </div>
          </div>
      </div>
    `
    $('body').append(template)
  }


  this.rpd = function(cnfg) {
    var dialogoRapido = $('#iu-dialogoRapido')

    if (dialogoRapido.parents(':last').is('html')) {
      _cargarDialogoRpd(cnfg)
    } else {
      _crearDialogoRapido(cnfg)
      _cargarDialogoRpd(cnfg)
    }

    if (typeof cnfg.fnAceptar == 'function') 
      this.fn.aceptar = function(){
        var respuestaUsuario = $('#iu-dialogoRapido .respuestaUsuario').val()
        cnfg.fnAceptar(respuestaUsuario);
      }
    else this.fn.aceptar = function(){};

    if (typeof cnfg.fnCancelar == 'function') this.fn.cancelar = cnfg.fnCancelar;
    else this.fn.cancelar = function(){};
  };
}

export default IUDlg

/**
 * Clase para herramientas
 */
import accounting from 'accounting/accounting.js'

function Herramienta() {
  /**
   * Método que devuelve un número en formato de moneda
   */
  this.fmon = function(numero, opciones = null, codigo = null) {
    if(opciones != null) return accounting.formatMoney(numero, opciones) + (codigo ? ' '+codigo : '')
    else return accounting.formatMoney(numero) + (codigo ? ' '+codigo : '')
  }
}

export default Herramienta
